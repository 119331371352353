<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start" color="primary">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Přehled odpovědí</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="none">
      <ion-item v-for="question in getResults.data" :key="question.id">
        <template
          v-if="
            question.type_question.id == 1 || question.type_question.id == 2
          "
        >
          <ion-icon slot="start" color="primary" :icon="radioButtonOn">
          </ion-icon>
          <ion-label>
            <ion-img
              v-if="question.media.length > 0"
              :src="question.media[0].url"
            ></ion-img>
            <p class="description">
              <strong>{{ question.description }}</strong>
            </p>
            <ion-icon size="2" class="user-icon" :icon="document"></ion-icon>
            <ion-grid>
              <ion-row>
                <ion-col
                  v-for="item in question.items"
                  :key="item.uid"
                  :size="item.text.length > 30 ? '12' : '6'"
                >
                  <template v-if="item.text != undefined">
                    <span :class="item.is_correct ? 'correct' : 'wrong'">{{
                      item.text
                    }}</span>
                  </template>
                  <template v-else>
                    <ion-img
                      :src="item.image"
                      :class="item.is_correct ? 'correct' : 'wrong'"
                    ></ion-img>
                  </template>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="divider"></div>
            <p>
              <ion-icon class="user-icon" :icon="person"></ion-icon>
              <ion-grid>
                <ion-row>
                  <template v-for="item in question.items" :key="item.uid">
                    <template v-if="item.text != undefined">
                      <ion-col size="6" v-if="item.user_select"
                        ><span class="selected">{{ item.text }}</span></ion-col
                      >
                    </template>
                    <template v-else>
                      <ion-col size="6" v-if="item.user_select">
                        <ion-img class="selected" :src="item.image"></ion-img
                      ></ion-col>
                    </template>
                  </template>
                </ion-row>
              </ion-grid>
            </p>
          </ion-label>

          <ion-icon
            v-if="checkAnswer(question)"
            slot="end"
            class="success"
            :icon="checkmark"
          ></ion-icon>
          <ion-icon v-else slot="end" class="danger" :icon="close"></ion-icon>
        </template>
        <template
          v-if="
            question.type_question.id == 3 || question.type_question.id == 4
          "
        >
          <ion-icon slot="start" color="primary" :icon="checkbox"> </ion-icon>
          <ion-label>
            <ion-img
              v-if="question.media.length > 0"
              :src="question.media[0].url"
            ></ion-img>
            <p class="description">
              <strong>{{ question.description }}</strong>
            </p>
            <ion-icon size="2" class="user-icon" :icon="document"></ion-icon>
            <ion-grid>
              <ion-row>
                <ion-col
                  v-for="item in question.items"
                  :key="item.uid"
                  size="6"
                >
                  <template v-if="item.text != undefined">
                    <span :class="item.is_correct ? 'correct' : 'wrong'">{{
                      item.text
                    }}</span>
                  </template>
                  <template v-else>
                    <ion-img
                      :src="item.image"
                      :class="item.is_correct ? 'correct' : 'wrong'"
                    ></ion-img>
                  </template>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="divider"></div>
            <p>
              <ion-icon class="user-icon" :icon="person"></ion-icon>
              <ion-grid>
                <ion-row>
                  <template v-for="item in question.items" :key="item.uid">
                    <template v-if="item.text != undefined">
                      <ion-col size="6" v-if="item.user_select"
                        ><span class="selected">{{ item.text }}</span></ion-col
                      >
                    </template>
                    <template v-else>
                      <ion-col size="6" v-if="item.user_select">
                        <ion-img class="selected" :src="item.image"></ion-img
                      ></ion-col>
                    </template>
                  </template>
                </ion-row>
              </ion-grid>
            </p>
          </ion-label>

          <ion-icon
            v-if="checkAnswer(question)"
            slot="end"
            class="success"
            :icon="checkmark"
          ></ion-icon>
          <ion-icon v-else slot="end" class="danger" :icon="close"></ion-icon>
        </template>
        <template
          v-if="
            question.type_question.id == 6 || question.type_question.id == 7
          "
        >
          <ion-icon slot="start" color="primary" :icon="text"> </ion-icon>
          <ion-label>
            <ion-img
              v-if="question.media.length > 0"
              :src="question.media[0].url"
            ></ion-img>
            <p class="description">
              <strong>{{ question.description }}</strong>
            </p>
            <p class="succsess">
              <ion-icon class="user-icon" :icon="document"></ion-icon
              >{{ question.items[0].text }}
            </p>

            <div class="divider"></div>
            <p>
              <ion-icon class="user-icon" :icon="person"></ion-icon>
              {{ question.user_answer }}
            </p>
          </ion-label>
          <ion-icon
            v-if="checkAnswer(question)"
            slot="end"
            class="success"
            :icon="checkmark"
          ></ion-icon>
          <ion-icon v-else slot="end" class="danger" :icon="close"></ion-icon>
        </template>
        <template v-if="question.type_question.id == 5">
          <ion-icon slot="start" color="primary" :icon="move"> </ion-icon>
          <ion-label>
            <ion-img
              v-if="question.media.length > 0"
              :src="question.media[0].url"
            ></ion-img>
            <p class="description">
              <strong>{{ question.description }}</strong>
            </p>
            <p>
              <ion-icon class="user-icon" :icon="document"></ion-icon>
              <span v-html="parseDragDrop(question, false)"></span>
            </p>
            <div class="divider"></div>
            <p>
              <ion-icon class="user-icon" :icon="person"></ion-icon>
              <span v-html="parseDragDrop(question, true)"></span>
            </p>
          </ion-label>
          <ion-icon
            v-if="checkAnswer(question)"
            slot="end"
            class="success"
            :icon="checkmark"
          ></ion-icon>
          <ion-icon v-else slot="end" class="danger" :icon="close"></ion-icon>
        </template>
        <template v-if="question.type_question.id == 8">
          <ion-icon slot="start" color="primary" :icon="informationCircle">
          </ion-icon>
          <ion-label>
            <ion-img
              v-if="question.media.length > 0"
              :src="question.media[0].url"
            ></ion-img>
            <p>{{ question.description }}</p>
          </ion-label>
        </template>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonLabel,
  IonItem,
  IonList,
  IonContent,
  IonNote,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import {
  close,
  text,
  radioButtonOn,
  checkbox,
  checkmark,
  move,
  person,
  informationCircle,
  document,
} from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "ResultModal",
  setup() {
    return {
      close,
      text,
      checkmark,
      radioButtonOn,
      checkbox,
      move,
      person,
      informationCircle,
      document,
    };
  },
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonContent,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
  },
  computed: {
    ...mapGetters({
      getResults: "questions/getResults",
    }),
  },
  methods: {
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: true,
      });
    },
    checkAnswer(answer) {
      console.log(answer);
      let rightAnswer = answer.items[0].text;
      let userAnswer = answer.user_answer;
      let flag = false;
      let ids = "";
      let items = answer.items;
      const regex = /{{.+?}}/g;
      switch (answer.type_question.id) {
        case 1:
        case 2:
          answer.items.forEach((el) => {
            if (el.user_select != undefined) {
              console.log(
                el.is_correct,
                el.user_select,
                el.user_select && el.is_correct
              );
              if (el.user_select && el.is_correct) {
                flag = true;
              }
            }
          });
          return flag;
        // break;
        case 3:
        case 4:
          flag = true;
          answer.items.forEach((el) => {
            if (el.is_correct == true) {
              if (el.user_select == undefined) {
                flag = false;
              }
            }
          });
          return flag;
        case 5:
          ids = answer.description_words.match(regex);

          ids.forEach((el, index) => {
            ids[index] = el.replaceAll("{", "").replaceAll("}", "");
            items = _.find(answer.items, (o) => {
              return o.uid == ids[index];
            });
            if (items.position == index) {
              flag = true;
            } else {
              flag = false;
            }
          });
          return flag;
        case 6:
        case 7:
          if (!answer.is_uppercase && userAnswer) {
            rightAnswer = rightAnswer.toUpperCase();
            userAnswer = userAnswer.toUpperCase();
          }
          if (!answer.is_uppercase && userAnswer) {
            rightAnswer = rightAnswer
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "");
            userAnswer = userAnswer
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "");
          }
          if (userAnswer == rightAnswer) {
            return true;
          }
          return false;
        case 8:
          break;
      }
    },
    parseDragDrop(answer, user) {
      const regex = /{{.+?}}/g;

      let ids = answer.description_words.match(regex);

      let items = answer.items;

      ids.forEach((el, index) => {
        ids[index] = el.replaceAll("{", "").replaceAll("}", "");
      });

      let resultText = answer.description_words
        .replace("<p>", "")
        .replace("</p>", "");
      ids.forEach((el, index) => {
        // debugger;
        let find = {};
        if (user) {
          find = _.find(items, (o) => {
            if (o.position != undefined) {
              return o.position == index;
            } else {
              return false;
            }
          });
        } else {
          find = _.find(items, (o) => {
            return o.uid == el;
          });
        }
        if (find != undefined) {
          resultText = resultText.replace(
            `{{${el}}}`,
            `<strong style="color: var(--ion-color-primary)">${find.text}</strong>`
          );
        }
      });

      return resultText;
      // debugger;
    },
  },
};
</script>

<style scoped>
ion-item {
  margin-bottom: 16px;
  white-space: break-spaces;
  overflow: unset;
}
.success {
  color: var(--ion-color-success);
}
.danger {
  color: var(--ion-color-danger);
}
.divider {
  border-bottom: 1px solid var(--ion-color-medium);
  margin: 4px 0px;
}
.user-icon {
  color: var(--ion-color-primary);
  position: relative;
  top: 2px;
  margin-right: 8px;
}
ion-label {
  white-space: break-spaces !important;
  overflow: auto !important;
}
.wrong {
  border: 4px solid var(--ion-color-medium);
  color: var(--ion-color-medium);
}
.correct {
  border: 4px solid var(--ion-color-success);
  color: var(--ion-color-success);
}
.selected {
  border: 4px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
}
span.correct,
span.wrong,
span.selected {
  display: block;
  border: 2px solid;
  padding: 2px 4px;
}
ion-col {
  /* text-align: center; */
  /* widows: 100%; */
}
.description {
  margin-bottom: 16px;
  font-size: 16px;
}
ion-lable p {
  /* font-size: 16px; */
}
.color-primary {
  color: var(--ion-color-primary) !important;
}
</style>
