<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="end()">
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Výsledek</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="getDetail.is_chart_map != 1">
      <h2>Kvíz dokončen</h2>
      <div class="score ">
        <span class="stars ion-padding">
          <ion-icon
            :icon="
              getResults.evaluate.result_questions_correct >=
                Math.round(getResults.evaluate.result_questions_total / 3) &&
              getResults.evaluate.result_questions_correct != 0
                ? star
                : starOutline
            "
            :class="
              getResults.evaluate.result_questions_correct >=
                Math.round(getResults.evaluate.result_questions_total / 3) &&
              getResults.evaluate.result_questions_correct != 0
                ? 'full'
                : 'empty'
            "
          ></ion-icon>
          <ion-icon
            :icon="
              getResults.evaluate.result_questions_correct >=
              Math.round(getResults.evaluate.result_questions_total / 2)
                ? star
                : starOutline
            "
            :class="
              getResults.evaluate.result_questions_correct >=
              Math.round(getResults.evaluate.result_questions_total / 2)
                ? 'full'
                : 'empty'
            "
          ></ion-icon>
          <ion-icon
            :icon="
              getResults.evaluate.result_questions_correct ==
              getResults.evaluate.result_questions_total
                ? star
                : starOutline
            "
            :class="
              getResults.evaluate.result_questions_correct ==
              getResults.evaluate.result_questions_total
                ? 'full'
                : 'empty'
            "
          ></ion-icon>
        </span>
        <div class="result-text">
          {{ getResults.evaluate.result_questions_correct }} z
          {{ getResults.evaluate.result_questions_total }} odpovědí správně
        </div>
        <p class="show-results" @click="showResults()">
          Procházet odpovedi &gt;
        </p>
        <div class="points">
          <ion-list lines="none">
            <ion-item>
              <ion-icon slot="start" :icon="checkmarkCircleOutline"></ion-icon>
              <ion-label> Správně otázek</ion-label>
              <ion-note slot="end">
                {{ getResults.evaluate.result_questions_correct }}</ion-note
              >
            </ion-item>
            <ion-item>
              <ion-icon
                slot="start"
                class="best"
                :icon="checkmarkCircle"
              ></ion-icon>
              <ion-label class="best">Nejlepší výsledek</ion-label>
              <ion-note slot="end" class="best">{{
                getResults.evaluate.result_best_points
              }}</ion-note>
            </ion-item>
          </ion-list>
          <div class="divider"></div>
          <ion-list lines="none">
            <ion-item>
              <ion-icon slot="start" :icon="stopwatchOutline"></ion-icon>
              <ion-label> Čas pokusu</ion-label>
              <ion-note slot="end">
                {{ getTime(getResults.evaluate.result_time) }}</ion-note
              >
            </ion-item>
            <ion-item>
              <ion-icon slot="start" class="best" :icon="stopwatch"></ion-icon>
              <ion-label class="best">Nejlepší čas</ion-label>
              <ion-note slot="end" class="best">
                {{ getTime(getResults.evaluate.result_best_time) }}</ion-note
              >
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-content>
    <ion-content v-else>
      <h2>Příběh dokončen</h2>
      <div class="points">
        <ion-item>
          {{ getResults.description_end }}
        </ion-item>
      </div>
    </ion-content>
    <ion-footer>
      <ion-footer class="ion-padding">
        <ion-button color="primary" expand="block" @click="end()"
          >Ukončit</ion-button
        >
      </ion-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import moment from "moment";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonFooter,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonItem,
  IonList,
  IonLabel,
  IonNote,
  modalController,
} from "@ionic/vue";
import {
  checkmarkCircleOutline,
  checkmarkCircle,
  stopwatchOutline,
  stopwatch,
  closeOutline,
  starOutline,
  star,
} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import ResultModal from "../components/ResultModal.vue";
export default {
  name: "Results",
  setup() {
    return {
      starOutline,
      star,
      closeOutline,
      checkmarkCircleOutline,
      checkmarkCircle,
      stopwatchOutline,
      stopwatch,
    };
  },
  components: {
    IonContent,
    IonPage,
    IonIcon,
    IonFooter,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonItem,
    IonList,
    IonLabel,
    IonNote,
  },
  props: {
    data: Object,
  },
  computed: {
    ...mapGetters({
      getResults: "questions/getResults",
      getDetail: "quiz/getDetail",
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      downloadQuizDetail: "quiz/downloadQuizDetail",
      changeLoading: "quiz/changeLoading",
    }),
    end() {
      let id = this.$route.params.id;
      this.downloadQuizDetail({ id: id }).then((res) => {
        this.$router.replace({
          name: "Quiz",
        });
      });
    },
    getTime(time) {
      return moment()
        .startOf("day")
        .seconds(time)
        .format("HH [h] mm [m] ss [s]");
    },

    async showResults() {
      let self = this;
      const modal = await modalController.create({
        component: ResultModal,
        cssClass: "result-modal",
        componentProps: {},
      });

      modal.present();

      modal.onDidDismiss().then((data) => {
        // self.loadPosts();
      });
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 16px;
}
.score {
  margin: 16px 0px;
}
.stars {
  justify-content: center;
  display: flex;
  font-size: 48px;
}
.stars ion-icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%;
}
.stars .full {
  color: var(--ion-color-primary);
}
.stars .empty {
  color: var(--ion-color-medium);
}
.result-text {
  text-align: center;
  font-size: 14px;
  color: var(--ion-color-medium);
  margin-bottom: 32px;
}
.show-results {
  text-align: center;
  font-weight: 700;
}
ion-item {
  --ion-background-color: #f7fafc;
  /* text-align: center; */
}
ion-list {
  --ion-background-color: #f7fafc;
}
.divider {
  border-bottom: 2px solid var(--ion-color-medium);
}
ion-note {
  font-size: 16px;
  color: var(--ion-text-color);
  font-weight: 700;
}
.best {
  color: var(--ion-color-primary);
}
.points {
  margin-top: 32px;
}
</style>
